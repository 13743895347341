
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useLocation } from "react-router-dom";
import logo from "../img/logo.png"
import { pallete } from './Pallete';
import BurgerMenu from './BurgerMenu';

const Navigation = () =>{
  const { pathname } = useLocation();
    return(
        <>
        <StyledNav> 
              <Link to="/" id="logo"><img src={logo} alt="Logo"/></Link>          
            <ul>
                <li>
                  <Link to="/" >Home</Link>
                </li>
                <li>
                  <Link to="/about" >About Us</Link>
                </li>
                <li>
                  <Link to="/contact" >Contact</Link>                  
                </li>
            </ul>
        </StyledNav>
        <BurgerMenu />
        </>
    )
}

const StyledNav = styled.nav`

  width:100%;
  min-height: 10vh;
  display: flex;
  margin: auto;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 10rem;
  position: relative !important;
  top: 0;
  z-index: 100;
  background-color: ${pallete.White};
    img{
      width: 120px;
      height:auto;  
    } 
    a{
      font-family: 'Kanit', sans-serif;
      background-color:${pallete.DGreen};
      text-decoration: none;
      -webkit-background-clip: text;
      -moz-background-clip: text;
      -webkit-text-fill-color: transparent; 
      -moz-text-fill-color: transparent;
    }
    ul{
        display: flex;
        list-style: none;
    }
    li{
        padding-left: 7rem;
        position: relative;
    }
    @media (max-width: 1300px) {
      padding: 1rem 1rem;
      #logo {
        display: inline-block;
        margin: 1rem;
      }
      ul {
        padding: 2rem;
        justify-content: space-around;
        width: 100%;
        li {
          padding: 0;
        }
      }
    }
  @media (max-width: 460px) {
    ul {
        justify-content: space-between;
    }
  }
  @media (max-width: 680px) {
    display:none;
  }
`;



export default Navigation;


