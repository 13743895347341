import React from "react";
import styled from "styled-components";
import logo from "../img/logo.png";
// import Components
import TopMainPage from "../components/TopMainPage";
import TopMainDscrpt from "../components/TopMainDscrpt";
import Stages from "../components/Stages"
import LetsWork from "../components/LetsWork";

const MainPage = () =>{

    return(
        <Container>
            <img src={logo} alt="logo"/>
            <TopMainPage/>
            <TopMainDscrpt/>
            <Stages/>
            <LetsWork/>
        </Container>
    )

}

const Container = styled.div`
    position:relative;
    overflow: hidden;
    >img{
        display:none;
        width:80%;
        position:fixed;
        left: 50%;
        top: 10%;
        transform: translate(-50%, -10%);
        z-index:20;
        @media(max-width:680px){
            display:block;
        }
    }
    

`;

export default MainPage;