import React from "react";
import styled from "styled-components";
import { pallete } from './Pallete';

import StyledButton from "./StyledButton";
import forest from "../img/forest.jpg"

import {motion } from "framer-motion"
import { stagger , childFade} from "../anim";


const TopMainPage = () =>{

    return(
        <Container>
           <motion.div 
                variants={stagger(0.75)} 
                initial="hidden"
                animate="show"
            >
                <motion.h1 variants={childFade}>Ecoplastex</motion.h1>
                <motion.p variants={childFade}>
                ECOPLASTEX LTD is an experienced traders and reliable suppliers of waste plastic, post-industrial plastic scrap, post-
                commercial plastic scrap, and post-consumer plastic scrap for the recycling industry for both domestic and international 
                markets. We handle a broad range of plastics including LDPE, PET, PP, ABS, Nylon, PS, EPS, PC, specialised engineering 
                plastics, and HDPE plastic suitable for further recycling. 
                </motion.p>
                <motion.div variants={childFade}>
                    <StyledButton name="Contact" url="/contact" />
                </motion.div>
           </motion.div>
        </Container>
    )

}

const Container = styled.div` 
    position:relative;
    width:100%;
    display:flex;
    justify-content:center;
    background-image: url(${forest});
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment:fixed;
    background-position: center ;
    >div{
        width: 60%;
        height: 100vh;
        text-align:center;
        display:flex;
        flex-direction:column;
        align-items:center;
        justify-content:center;
    }
    h1{  
        font-size:6rem;
        font-weight:400;
        line-height: 70px;
        margin-bottom:5rem;
        color:${pallete.DGreen};
        font-family: 'Bruno Ace SC', cursive;
        @media(max-width:680px){
            visibility: hidden;
        }
    }
    p{
        font-size:2rem;
        font-weight:300;
        color: ${pallete.White};
        margin-bottom:5rem;
    }
    @media (max-width:1100px){
        h1{  
            line-height: 4rem;
        }
    }
    @media (max-width:1300px){
        >div{
            width:90%;           
        }
    }
    @media (max-width:1024px){
        >div{
            padding-bottom:8rem;
        }
        
    }
    @media (max-width:820px){
        >div{
            padding-bottom:0rem;
        }
        
    }
    @media (max-width:660px){
        h1{  
            font-size:4rem;
        }
        h1{  
            line-height: 6rem;
        }
        
    }
    @media (max-width:400px){
        h1{  
            font-size:3.5rem;
        }
        p{
            font-size:1.5rem;
        }
    }
    @media (max-width:325px){
        h1{  
            line-height: 4rem;
        }
    }
    @media (max-width:300px){
        h1{  
            font-size:3.2rem;
        }
    }

`


export default TopMainPage;