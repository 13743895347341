import React from "react";
import styled from "styled-components";
// import Components
import AboutDscrpt from "../components/AboutDscrpt";


const About = () =>{

    return(
        <Container>   
            <AboutDscrpt/>
        </Container>
    )

}

const Container = styled.div`
    position: relative;
    overflow: hidden;
`;

export default About;