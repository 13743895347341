import React from "react";

import { Link } from 'react-router-dom';
import { useLocation } from "react-router-dom";
//Styles
import styled from "styled-components";
import { pallete } from "./Pallete";

const Footer = () =>{
    return(
            <Container>
                <ContactContainer>  
                    <div>
                        <h4>Our contact</h4>
                    </div>
                    <ul>
                        <li>ECOPLASTEX LTD</li>
                        <li>Prama House,</li>
                        <li>267 Banbury Road,</li>
                        <li>Oxford,</li>
                        <li>England, </li>
                        <li>OX2 7HT</li>
                        <li>England, </li>
                        <li>Mail: info@ecoplastexltd.com<button onClick={() => {navigator.clipboard.writeText("info@ecoplastexltd.com") ; alert("Press okay to copy! :)")}}>Copy</button> </li>                        
                        <li>Mob: +447727033933<button onClick={() => {navigator.clipboard.writeText("+447727033933") ; alert("Press okay to copy! :)")}}>Copy</button> </li>    
                    </ul>
                </ContactContainer>
                <SiteMap>  
                    <div>
                        <h4>Sitemap</h4>
                    </div>
                    <ul>
                        <li>
                            <Link to="/" >Home</Link>
                        </li>
                        <li>
                            <Link to="/about" >About Us</Link>
                        </li>
                        <li>
                            <Link to="/contact" >Contact</Link>                  
                        </li>
                    </ul>
                </SiteMap>
            </Container>                    
    )
}



const Container = styled.div`
    position:relative;
    display:flex;
    padding: 5rem 10rem;
    justify-content: space-evenly;
    background-color:${pallete.White};
    z-index:1;
    div{
        ul{
            list-style-type: none;
            font-size:1.5rem;
            color:${pallete.DGreen};
            li{
                font-weight: 300;
            }
            
        }
        h4{

            font-weight: 500;
            text-transform: uppercase;
            color:${pallete.DGreen};
            letter-spacing:1px;
            margin-bottom:1rem;
        }
        button{
            cursor:pointer;
            padding:0.3rem 0.8rem;
            margin:0.5rem 0 0 1rem;
            font-family: 'Kanit', sans-serif;
            border:none;
            font-weight:300;
            text-decoration: none;
            color: ${pallete.DGreen};
            border: 2px solid ${pallete.DGreen};
            background:transparent;
            letter-spacing: 2px;
            text-align: center;
        }
    }
    @media(max-width:768px){
        padding: 2rem 1rem 3rem;
        flex-direction:column-reverse;
        text-align:center;
        div{
            margin:1rem 0 0 0;
            ul{
                letter-spacing:0.5px;
            }
        } 
        h2{
            font-size:3.5rem;
        }
    }
    @media(max-width:360px){
        h2{
            font-size:2.5rem;
        }
    }
`
const ContactContainer = styled.div`
    margin-right:2rem;
  
`
const SiteMap = styled.div`
    li{
        margin:1rem 0;
        a{
            list-style-type: none;
            font-size:1.5rem;
            color:${pallete.DGreen };
            font-weight: 300;
            text-decoration: none;
       
        }
    }
`
export default Footer;