
export const stagger = (staggerChildrenValue) => ({
  show: {
    transition: { staggerChildren: staggerChildrenValue },
  },
});


export const childVariants = {
    hidden: { 
        x:300,
        opacity: 0, 
    },
    show: {
      x: 0,
      opacity: 1,
    },
    
};
export const childFade = {
    hidden: { 
        opacity: 0, 
    },
    show: {
      opacity: 1,
      transition:{   
        duration:1,
      }
    },
    
};






