import React, { useState } from 'react';
import styled from 'styled-components';
import { pallete } from './Pallete';


const Form = () => {
    const TOKEN = '6164197430:AAGklbxb8cZCJSPQGuPSqcHGTekbfGPT3S0';
    const CHAT_ID = '2031952526'; 
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isFormSubmitSuccess, setIsFormSubmitSuccess] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    
    const handleSubmit = async (e) => {
      e.preventDefault();
      const data = { name, phoneNumber, email, message };
      const text = `Name: ${name}%0APhone Number: ${encodeURIComponent(
        phoneNumber
      )}%0AEmail: ${email}%0AMessage: ${message}`;
      const response = await fetch(
        `https://api.telegram.org/bot${TOKEN}/sendMessage?chat_id=${CHAT_ID}&text=${text}`
      );
      const result = await response.json();
      if (result.ok) {
        setIsFormSubmitSuccess(true);
        setName("");
        setPhoneNumber("");
        setEmail("");
        setMessage("");
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 5000);
      } else {
        setIsFormSubmitSuccess(false);
      }
      setIsFormSubmitted(true);
    };
    return (
      <FormContainer onSubmit={handleSubmit}>
        
        <Wrapper>
        {showNotification && (
            <Notification>
              <p>Form submitted successfully!</p>
            </Notification>
          )}
          {isFormSubmitted && !isFormSubmitSuccess && (
            <Notification>
              <p>Failed. Please try again.</p>
            </Notification>
        )}
          <Input
            type="text"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            type="tel"
            placeholder="+1234567890"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            pattern="^\+[0-9]*$"
          />
          <Input
            type="email"
            placeholder="Email *"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            required
          />
      
          <TextArea
            placeholder="Message"
            rows="4"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button type="submit">Send</Button>
         
        </Wrapper>
      </FormContainer>
    );
  };
const FormContainer = styled.form`
  position:relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background:${pallete.LightGray};
  padding:3rem;
  border-radius: 20px;
  width:40%;
  @media(max-width:1100px){
    width:80%;
  }
  @media(max-width:500px){
    padding:3rem 1rem;
  }
`

const Notification = styled.div`
  position:absolute;
  width:80%;
  top:0.5rem;
  font-family: 'Kanit', sans-serif;
  text-align:center;
  font-style:italic;
  font-size:1.5rem;
  color:${pallete.Gray};
  @media(max-width:360px){
    word-wrap:break-word;
  }
`;
const Input = styled.input`
  width:90%;
  border-radius: 10px;
  border:none;
  padding:1rem;
  margin:1rem;
  font-family: 'Kanit', sans-serif;
  transition: 0.5s;
  &:focus{
    transition: 0.5s;
    outline: none;
    box-shadow: 0 0 10px 1px ${pallete.DGreen};
  }
  &:hover{
    transition: 0.5s;
    outline: none;
    box-shadow: 0 3px 8px 1px ${pallete.DGreen};
  }
`

const TextArea = styled.textarea`
  width:90%;
  border-radius: 10px;
  border:none;
  resize: none;
  overflow: auto;
  padding:1rem;
  margin:1rem;
  font-family: 'Kanit', sans-serif;
  transition: 0.5s;

    &:focus{
      transition: 0.5s;
      outline: none;
      box-shadow: 0 0 10px 1px ${pallete.DGreen};
    }
    &:hover{
      transition: 0.5s;
      outline: none;
      box-shadow: 0 3px 8px 1px ${pallete.DGreen};
    }
    ::-webkit-scrollbar { 
    width: 6px; 
    height: 6px; 
    border:20px;
    }
    ::-webkit-scrollbar-track-piece { 
      background:transparent; 
      -webkit-border-radius: 6px; 
    }
    ::-webkit-scrollbar-thumb:vertical { 
      height: 5px; 
      background-color: #cccccc; 
    }
    ::-webkit-scrollbar-thumb:horizontal { 
      width: 5px; 
      background-color: #cccccc; 
    }
  `;

const Button = styled.button`
  background-color: ${pallete.DGreen};
  color: ${pallete.White};
  font-family: 'Kanit', sans-serif;
  font-size:1.2rem;
  font-weight:300;
  border: none;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  &:hover {
    background-color: ${pallete.LGreen};
  }
`;

export default Form;
