export const pallete = {
    LightGray: "#ccc",
    Gray:"rgb(40, 40, 40);",
    White:"#FFFFFF",
    BackGround: "#1b1b1b",
    BackGroundOpacity: "rgb(27, 27, 27, 0.9)",
    BlueAdobe: "#0087A3",
    DGreen: "#374e48",
    MGreen:"#428c51",
    LGreen:"#97c088",

}