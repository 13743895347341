import React from "react";


import styled from "styled-components";
import { pallete } from './Pallete';

import {motion } from "framer-motion"
import { stagger , childFade} from "../anim";
import { useScroll } from "./useScroll";



import earth from "../img/earth.png"
import team from "../img/team.png"
import brain from "../img/brain.png"
import misson from "../img/misson.png"
import bottles from "../img/bottles.jpg"



const TopMainDscrpt = () =>{
    const [element, controls] = useScroll({ layoutEffect: false });
    return(
        <Container>
                <Items  variants={stagger(0.75)}
                 animate={controls}
                 ref={element}
                 initial="hidden"
                 >
                    <h2>With extensive industry experience, our company has emerged as a respected player in the field, dedicated to the efficient recycling of plastic waste.</h2>
                    <Item variants={childFade}>
                        <div>
                            <img src={team} alt="team"/>
                            <h3>Team</h3>
                        </div>
                        <p>Our adept team diligently gathers discarded plastic, swiftly transporting it to our cutting-edge production facility for meticulous recycling procedures.                       
                        </p>
                        <p>
                            This meticulous process converts the plastic into granules, unlocking a vast array of applications for global manufacturers.
                        </p>
                    </Item>
                    <Item variants={childFade}>
                        <div>
                            <img src={misson} alt="misson"/>
                            <h3>Mission</h3>
                        </div>
                        <p>Despite the widespread usage of LDPE as a primary plastic variant among manufacturers, its detrimental impact on the environment remains a pressing concern.
                        </p>
                        <p>Thus, prioritizing efficient recycling methods becomes imperative.</p>
                    </Item>
                    <Item variants={childFade}>
                        <div>
                            <img src={brain} alt="brain"/>
                            <h3>Flexibility</h3>
                        </div>
                        <p>Through strategic collaborations, we strive to revolutionize plastic waste into an invaluable and sustainable resource material.</p>
                        <p>If you are contemplating the sale of packaging film waste, we warmly invite you to engage with us for detailed discussions regarding waste collection or the procurement of meticulously processed LDPE granules.
                        </p>
                    </Item>
                    <Item variants={childFade}>
                        <div>
                            <img src={earth} alt="earth"/>
                            <h3>Materials logistics</h3>
                        </div>
                        <p>Moving both scrap materials and recycled plastic around Europe in most ecological and efficient way 
                            connecting suppliers, sorters, and manufacturers and building sustainable supply chain in plastic 
                            recycling industry.
                        </p>
                    </Item>
                </Items>
        </Container>
    )
}

const Container = styled.div`
    padding: 5rem 0;
    position:relative;
    background-image: url(${bottles});
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment:fixed;
    background-position: center ;
    
    `
const Items = styled(motion.div)`
    margin:0 auto;
    width:95%;
    backdrop-filter: blur(2px);
    border-radius:25px;
        h2{
            text-align:center !important;
            font-size:2rem;
            font-weight:400;
            color:${pallete.DGreen};
            margin: 0 auto 4rem;
            padding-top:2rem;
            width:90%;
            font-family: 'Bruno Ace SC', cursive;
        }
    `
const Item = styled(motion.div)`
    margin:0 auto 2rem;
    width:90%;
    div{
        display:flex;
        align-items:center;
        margin-bottom:2rem;
        img{
            width:50px;
            height:auto;
            margin-right:2rem;
        }
        h3{
            color:${pallete.DGreen};
            font-size:1.8rem;
            font-weight:300;
        }
    }
    p{
        color:${pallete.DGreen};
        font-size:1.5rem;
        font-weight:300;
        padding-bottom:1rem;
    }
`

export default TopMainDscrpt;