import React from "react";

import styled from "styled-components";
import {motion } from "framer-motion"
import {childFade} from "../anim";
import logo from "../img/logo.png";
import { pallete } from "../components/Pallete";
import Form from "../components/Form";
import stage2 from "../img/stage2.jpg"


const Contact = () =>{

    return(
        <motion.div
            exit="exit"
            variants={childFade}
            initial="hidden"
            animate="show"
        >
            <LogoDiv>
                <img src={logo} alt="logo"/>
            </LogoDiv>
            <Info>
                <img src={stage2} alt="Background" />
                <WrapperContant>
                    <div>
                        <h3>Please place your requests, offers or suggestions to :</h3>
                    </div>
                    <div>
                        <ul>
                            <li>ECOPLASTEX LTD</li>
                            <li>Prama House,</li>
                            <li>267 Banbury Road,</li>
                            <li>Oxford,</li>
                            <li>England, </li>
                            <li>OX2 7HT</li>
                            <li>England, </li>
                            <li>Mail: info@ecoplastexltd.com<button onClick={() => {navigator.clipboard.writeText("info@ecoplastexltd.com") ; alert("Press okay to copy! :)")}}>Copy</button> </li>                        
                            <li>Mob: +447727033933<button onClick={() => {navigator.clipboard.writeText("+447727033933") ; alert("Press okay to copy! :)")}}>Copy</button> </li>    
                        </ul>
                    </div>
                </WrapperContant>
                <WrapperForm>
                    <Form/>
                </WrapperForm>
            </Info>
                <MapWrapper>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2468.4895820315223!2d-1.2683947881938666!3d51.77893769071372!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876c414708935ed%3A0x449ff4c8b5edc78c!2zUHJhbWEgSG91c2UsIDI2NyBCYW5idXJ5IFJkLCBTdW1tZXJ0b3duLCBPeGZvcmQgT1gyIDdIUSwg0JLQtdC70LjQutC-0LHRgNC40YLQsNC90LjRjw!5e0!3m2!1sen!2suk&amp;hl=en" title="Map" loading="lazy"></iframe>
                </MapWrapper>
        </motion.div>
    )
    
}

const LogoDiv = styled.div`
    position:relative;
    width:90%;
    margin: 5rem auto 3rem;
    z-index:100;
    display:flex;
    justify-content:center;
    >img{
        width:400px;
        height:auto;
        @media(max-width:1280px){
            width:300px;
        }
        @media(max-width:360px){
            width:220px;
        }
    }
`

const Info = styled.div`
    position:relative;
    z-index:1;
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding: 15rem 10rem;
    overflow:hidden;
    
    >img{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index:0;
        filter: brightness(0.5);
    }
    >div{
        width:45%;
        margin:0 2rem;
        z-index:10;
        h3{
            width:100%;
            font-size:2.5rem;
            padding-bottom:1rem;
            color:${pallete.White};
            font-weight:500;
        }
        ul{
            color:${pallete.White};       
            font-size:1.5rem;
            list-style-type: none;
            li{
                display:flex;
                align-items:center;
                margin:1rem 0;
                font-weight:200;
                button{
                    cursor:pointer;
                    padding:0.3rem 0.8rem;
                    margin-left:1rem;
                    font-family: 'Kanit', sans-serif;
                    border:none;
                    font-weight:100;
                    text-decoration: none;
                    color: ${pallete.White};
                    border: 2px solid ${pallete.DGreen};
                    background:transparent;
                    letter-spacing: 2px;
                    text-align: center;
                }
            }
        }
    }
    @media(max-width:1200px){
        padding: 15rem 1rem;
    }
    @media(max-width:1050px){
        div{
            h3{
                margin-bottom:2rem;
            }
        }
    }
    @media(max-width:860px){
        flex-direction:column;
        div{
            width:90%;
            margin:0rem;
            h3{
               font-size:3rem;
               line-height:40px;
            }
            ul{
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
               li{
                    margin:0.5rem 0;
               }
            }
        }
        
    }
    @media(max-width:680px){
        padding: 10rem 1rem;
        div{
            h3{
                font-size:2.5rem;
            }
        }
    }

    @media(max-width:600px){
        div{
            h3{
                font-size:2rem;
                line-height:30px;
            }
        }
    }
    @media(max-width:360px){
        div{
           width:98%;
        }
    }
`
const WrapperContant = styled.div`
backdrop-filter: blur(2px);
border:25px;
    @media(max-width:860px){
        display:flex;
        flex-direction:column;
        width:90%;
        text-align:center;
        justify-content:space-evenly;
        align-items:center;
    }
`
const WrapperForm = styled.div`
    div{     
        width:100% !important;
    } 
    @media(max-width:860px){
        div{
          margin-top:5rem;
        }
    }

`

const MapWrapper = styled.div`
    position:relative;
    z-index:1;
    overflow: hidden;
    iframe{
        width:100%;
        height:550px;
        border:none;
        margin-bottom: -10px;
    }
    @media(max-width:1300px){
        iframe{
            width:100vw;
            height:350px;
            
        }
    }
`
export default Contact;