import React from "react";


import styled from "styled-components";
import { pallete } from './Pallete';
// import Slider from "./Slider";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; 




import img1 from "../img/1.jpg"
import img2 from "../img/2.jpg"
import img3 from "../img/3.jpg"
import img4 from "../img/4.jpg"
import img5 from "../img/5.jpg"
import img6 from "../img/6.jpg"
import img7 from "../img/7.jpg"
import img8 from "../img/8.jpg"
import img9 from "../img/9.jpg"
import img10 from "../img/10.jpg"
import img11 from "../img/11.jpg"
import img12 from "../img/12.jpg"
import img13 from "../img/13.jpg"
import img14 from "../img/14.jpg"
import img15 from "../img/15.jpg"
import img16 from "../img/16.jpg"
import img17 from "../img/17.jpg"
import img18 from "../img/18.jpg"
import img19 from "../img/19.jpg"
import img20 from "../img/20.jpg"

import stage from "../img/stage.jpg"



const Stages = () =>{
    const images = [
        {
            image: img2,
            text: 'LDPE Natural',
        },
        {
            image: img4,
            text: 'LDPE (Wine Bags)',
        },
        {
            image: img5,
            text: 'LDPE 95/05',
        },
       
        {
            image: img7,
            text: 'LDPE M&S A GRADE',
        },
       
        {
            image: img10,
            text: 'HDPE (Cans)',
        },{
            image: img11,
            text: 'HD Drums',
        },{
            image: img12,
            text: 'PVC(Polyvinyl Chloride)',
        },{
            image: img13,
            text: 'PP A grade sacks',
        },{
            image: img14,
            text: 'PP (Corex Board)',
        },{
            image: img15,
            text: 'PP unprinted rolls.',
        },{
            image: img16,
            text: 'PS Hangers',
        },{
            image: img17,
            text: 'PET (Polyethylene Terephthalate)',
        },{
            image: img18,
            text: 'PET G Production Scrap',
        },{
            image: img19,
            text: 'EPS Rolls',
        },{
            image: img20,
            text: 'Car Bumpers',
        },
        {
            image: img1,
            text: 'LDPE (Low Density Polyethylene)',
        },
        {
            image: img3,
            text: 'LDPE-Shrink wrap',
        },
        {
            image: img6,
            text: 'LDPE 98/02',
        },
        {
            image: img8,
            text: 'LDPE 100% Baled',
        },
        {
            image: img9,
            text: 'HDPE Bottles',
        },
        

      ];
    return(
        <Container>
            <Items>
                <h2>Materials we are trading and supplying</h2>
                <CarouselWrapper>
                    <CustomCarousel 
                        emulateTouch={false}  
                        infiniteLoop={true} 
                        renderIndicator={false}
                        showThumbs={true}
                        autoPlay={true}
                        swipeable={false}
                        stopOnHover={false}
                        showArrows={false}
                    >
                        {images.map((item ,index) => {
                            return(
                                <div key={index}>
                                    <img src={item.image} />
                                    <p>{item.text}</p>
                                </div>
                            )
                        })}
                    </CustomCarousel>
                </CarouselWrapper>
            </Items>
                
        </Container>
    )
}

const Container = styled.div`
    padding: 5rem 0;
    background-image: url(${stage});
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment:fixed;
    background-position: center ;
    
    `
const Items = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin:0 auto;
    width: 95%;
    backdrop-filter: blur(4px);
    border-radius: 25px;
    h2 {
        text-align: center !important;
        font-size: 2rem;
        font-weight: 400;
        color: ${pallete.DGreen};
        margin: 0 auto 4rem;
        padding-top: 2rem;
        width: 90%;
        font-family: 'Bruno Ace SC', cursive;
    }
`
const CarouselWrapper = styled.div`
    max-width: 65%;
    margin: 0 auto;

    div {
        img {
            overflow:hidden;
            width:100%;
            max-height: 500px;
            object-fit: cover;
            padding:0 1rem;
        }
        p {
            color: ${pallete.DGreen};
        }
    }
    @media(max-width:1300px){
        max-width: 90%;
    }
`;
const CustomCarousel = styled(Carousel)`
  .carousel .control-arrow {
    background: none;
   

  }
  .carousel .control-arrow:hover {
    background: transparent;

  }
  
  .carousel .control-next.control-arrow:before {
    border-left: 20px solid ${pallete.DGreen};
  }
  
  .carousel .control-prev.control-arrow:before {
    border-right: 20px solid ${pallete.DGreen};
    
  }
  .carousel .slide {
        display: flex; 
        justify-content: center;
    } 

  .carousel.carousel-slider .control-arrow:before, .carousel .control-arrow:before {
    display: none;
  }

`;

export default Stages;