import React from "react";
import styled from "styled-components";
import { pallete } from "./Pallete";

import {motion } from "framer-motion"
import { stagger , childFade, } from "../anim";

import team from "../img/team.png";
import aboutus from "../img/aboutus.jpg";


const AboutDcrpt = () =>{

    return(
        <Container 
            variants={stagger(0.75)} 
            initial="hidden"
            animate="show">
                <motion.h1 variants={childFade}>Ecoplastex</motion.h1>
                <Description variants={childFade}>
                    <p>
                    ECOPLASTEX LTD is an experienced traders and reliable suppliers of waste plastic, post-industrial plastic scrap, post- commercial plastic scrap, and post-consumer plastic scrap for the recycling industry for both domestic and international markets. We handle a broad range of plastics including LDPE, PET, PP, ABS, Nylon, PS, EPS, PC, specialised engineering plastics, and HDPE plastic suitable for further recycling. 
                    </p>
                </Description>
                <Item variants={childFade}>
                    <div>
                        <img src={team} alt="icon"/>
                    </div>
                    <div>
                        <p>Extensive Range of Plastic Types: We offer a wide selection of plastic types, including LDPE, PET, PP, ABS, Nylon, PS, EPS, PC, specialized engineering plastics, and HDPE. This diverse range allows us to cater to the specific recycling needs of our clients, ensuring that we provide suitable materials for their recycling processes.</p>
                        <p>Reliable and Experienced Traders: With years of experience in the industry, we have established ourselves as reliable traders. Our team possesses extensive knowledge and expertise in the plastic recycling market, enabling us to source and supply high-quality plastic scraps consistently.</p>
                        <p>Domestic and International Market Coverage: We serve both domestic and international markets, providing our products to recycling companies worldwide. Whether you require plastic waste for local recycling facilities or for export purposes, we can meet your needs with our efficient supply chain and global network.</p>
                        <p>Commitment to Sustainability: As a responsible supplier, we are committed to promoting sustainability in the recycling industry. By offering post-industrial, post-commercial, and post-consumer plastic scraps, we contribute to the circular economy and support the reduction of plastic waste in landfill sites.</p>
                        <p>Customized Solutions: We understand that each client has unique requirements and specifications. Therefore, we offer customized solutions to meet their specific demands. Whether you need a particular plastic type, quantity, or packaging, we work closely with our clients to ensure their satisfaction and provide tailored solutions.</p>
                    </div>
                </Item>
        </Container>
    )
}


const Container = styled(motion.div)`
    position:relative;
    background-image: url(${aboutus});
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment:fixed;
    background-position: center ;
    padding:10rem 0;
    
    h1{
        text-align:center;
        font-size:5rem;
        font-weight:300;
        color:${pallete.DGreen};
        margin:0 auto 4rem; 
        width:90%;
        backdrop-filter: blur(4px);
        border:25px;
        font-family: 'Bruno Ace SC', cursive;
       
       
    }
    @media (max-width:320px){
        h1{  
            font-size:3.2rem !important;
        }
    }
    >div{
        width:90%;
        margin:2rem auto;
        backdrop-filter: blur(2px);
        border-radius:25px;
        p{
            color:${pallete.BackGround};
            font-size:1.5rem;
            font-weight:300;
            padding-bottom:0.7rem;
        }
    }
    @media(max-width:460px){
        padding:10rem 0;
        h1{
            line-height:5rem;
            font-size:4rem;
       }
    }
`
const Description = styled(motion.div)`
    backdrop-filter: blur(2px);
    border:25px;
    p{
        font-weight:400 !important;
    }
`
const Item = styled(motion.div)`
    display:flex;
    align-items:center;
    div{
        img{
            width:100px;
            margin-right:2rem;
        }
    }
    @media(max-width:800px){
        flex-direction:column;
        img{
            width:100px;
            margin:1rem 0;
        }
    }
`
export default AboutDcrpt;