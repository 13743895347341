import React from "react";

import {motion } from "framer-motion"
import { stagger , childFade} from "../anim";
import { useScroll } from "./useScroll";

import styled from "styled-components";
import { pallete } from "./Pallete";

import superlight from "../img/superlight.jpg"
import StyledButton from "./StyledButton";

const LetsWork = () =>{ 
    const [element, controls] = useScroll({ layoutEffect: false });
    return(
        <Wrapper>
            <Container 
                variants={stagger(0.75)}
                animate={controls}
                ref={element}
                initial="hidden">
                    <motion.h2 variants={childFade}>Build your partnership with</motion.h2>
                    <motion.h2 variants={childFade}><span>Ecoplastex</span></motion.h2>
                    <motion.div  variants={childFade}>
                        <StyledButton name="Contact" url="/contact" />
                    </motion.div>
            </Container>
        </Wrapper>
    )
}
const Wrapper = styled.div`
    background-image: url(${superlight});
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment:fixed;
    background-position: center ;
    padding:7rem 0;
`
const Container = styled(motion.div)`
    position:relative;
    display:flex;
    flex-direction:column;
    justify-content:center;
    margin:0 auto;
    width:90%;

    h2{
        color:${pallete.White};
        font-size:8rem;
        text-align:center;
        font-weight:600;
        line-height:130px;
        
        span{
        font-family: 'Kanit', sans-serif;
        font-weight: bold;
        background-color: ${pallete.White};
        background-image:linear-gradient(to right, #374e48 , #97c088);
        background-size: 100%;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent; 
        -moz-text-fill-color: transparent;
    }
    }
    >div{
        margin:3rem auto;
    }
    @media(max-width:1360px){
        h2{
            line-height:115px;
        }
    }
    @media(max-width:780px){
        text-align:center;
        width:90%;
        h2{
            font-size:6rem;
            line-height:90px;
        }
    }
    @media(max-width:640px){
        h2{
            font-size:4rem;
            line-height:60px;
        }
    }
    @media(max-width:420px){
        h2{
            font-size:3rem;
            line-height:40px;
        }
    }
`
    


export default LetsWork;